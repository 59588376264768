<template>
  <div>
    <div class="d-flex flex-row-reverse">
      <CButton
        color="primary"
        @click="refreshSubscription()"
        class="float-righ"
      >
        <CIcon content="cil-reload" style="margin-right: 8px" />Refresh</CButton
      >
    </div>
    <CNav variant="tabs" role="tablist">
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tabPaneActiveKey === 0"
          @click="changeTab(0)"
        >
          Pending Verification
        </CNavLink>
      </CNavItem>
      <!-- <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tabPaneActiveKey === 1"
          @click="changeTab(1)"
        >
          Pending Payment
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tabPaneActiveKey === 2"
          @click="changeTab(2)"
        >
          Payment Failed
        </CNavLink>
      </CNavItem> -->
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tabPaneActiveKey === 3"
          @click="changeTab(3)"
        >
          Approved
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tabPaneActiveKey === 4"
          @click="changeTab(4)"
        >
          Rejected
        </CNavLink>
      </CNavItem>
    </CNav>
    <CTabContent>
      <CTabPane
        role="tabpanel"
        aria-labelledby="home-tab"
        :visible="tabPaneActiveKey === 0"
      >
        <Table />
      </CTabPane>
      <CTabPane
        role="tabpanel"
        aria-labelledby="profile-tab"
        :visible="tabPaneActiveKey === 1"
      >
        <Table />
      </CTabPane>
      <CTabPane
        role="tabpanel"
        aria-labelledby="contact-tab"
        :visible="tabPaneActiveKey === 2"
      >
        <Table />
      </CTabPane>
      <CTabPane
        role="tabpanel"
        aria-labelledby="contact-tab"
        :visible="tabPaneActiveKey === 3"
      >
        <Table />
      </CTabPane>
         <CTabPane
        role="tabpanel"
        aria-labelledby="contact-tab"
        :visible="tabPaneActiveKey === 4"
      >
        <Table />
      </CTabPane>
    </CTabContent>
  </div>
</template>

<script>
import Table from "@/components/subscription/Table.vue";

export default {
  components: {
    Table,
  },
  mounted() {
    this.$store.dispatch("selectSubscriptionTab", this.tabPaneActiveKey);
  },
  data() {
    return {
      tabPaneActiveKey: this.$store.state.subscription.selectedTab,
    };
  },

  methods: {
    changeTab(tab) {
      this.tabPaneActiveKey = tab;
      this.$store.dispatch("selectSubscriptionTab", tab);
    },
    refreshSubscription() {
      this.$store.dispatch("refreshSubscription");
    },
  },
};
</script>

<style>
</style>
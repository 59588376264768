<template>
  <div style="position: relative; min-height: 480px">
    <CTable
      align="middle"
      class="mb-0"
      hover
      responsive
      v-if="!$store.state.subscription.loading"
    >
      <CTableHead color="light" style="font-size: 0.8rem; height: 48px">
        <CTableRow>
          <CTableHeaderCell>Name</CTableHeaderCell>
          <CTableHeaderCell class="text-center">Surname</CTableHeaderCell>
          <CTableHeaderCell class="text-center">City</CTableHeaderCell>
          <CTableHeaderCell class="text-center">Country</CTableHeaderCell>
          <CTableHeaderCell class="text-center">Contact</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody v-if="$store.state.subscription.subscriptionData.length">
        <CTableRow
          v-for="item in $store.state.subscription.subscriptionData"
          :key="item.name"
          @click="rowSelected(item)"
        >
          <CTableDataCell>
            <div>{{ item.title }} {{ item.name }}</div>
            <div class="small text-medium-emphasis">
              {{ item.created }}
            </div>
          </CTableDataCell>
          <CTableDataCell class="text-center">
            {{ item.surname }}
          </CTableDataCell>
          <CTableDataCell class="text-center">
            {{ item.city }}
          </CTableDataCell>
          <CTableDataCell class="text-center">
            {{ item.country }}
          </CTableDataCell>

          <CTableDataCell class="text-center">
            Phone: {{ item.mobile_phone }} <br />
            Email: {{ item.email }}
          </CTableDataCell>
        </CTableRow>
        <CTableRow> </CTableRow>
      </CTableBody>
      <CTableBody v-else style="text-align: center">
        <CTableDataCell colspan="5" style="height: 120px">
          Subscription data is not available!
        </CTableDataCell>
      </CTableBody>
    </CTable>
    <div v-else>
      <loading
        v-model:active="loading"
        loader="dots"
        :is-full-page="fullPage"
      />
    </div>

    <CModal
      size="lg"
      :visible="subscriptionModal"
      alignment="center"
      @close="
        () => {
          subscriptionModal = false;
        }
      "
    >
      <CModalHeader> </CModalHeader>
      <CModalBody v-if="selectedSubscription" class="modal-custom-st">
        <div class="memebrship-status">
          {{ selectedSubscription.membership_status_label }}
        </div>
        <div class="row">
          <div class="col-4">Name</div>
          <div class="col-8">
            {{ selectedSubscription.title }}. {{ selectedSubscription.name }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Surname</div>
          <div class="col-8">
            {{ selectedSubscription.surname || "Not provided" }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Code</div>
          <div class="col-8">
            {{ selectedSubscription.code || "Membership in progress" }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Date of Birth</div>
          <div class="col-8">
            {{ $moment(Date(selectedSubscription.dob)).format("ll") }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Contact</div>
          <div class="col-8 contact-container">
            <div class="row">
              <div class="col-4">Email</div>
              <div class="col-8">
                {{ selectedSubscription.email }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Phone</div>
              <div class="col-8">
                {{ selectedSubscription.mobile_phone }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">Address</div>
          <div class="col-8 address-container">
            <div class="row">
              <div class="col-4">Street</div>
              <div class="col-8">
                {{ selectedSubscription.street }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">House Number</div>
              <div class="col-8">
                {{ selectedSubscription.house_number }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">City</div>
              <div class="col-8">
                {{ selectedSubscription.city }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Country</div>
              <div class="col-8">
                {{ selectedSubscription.country }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Postcode</div>
              <div class="col-8">
                {{ selectedSubscription.postcode }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">Interest</div>
          <div class="col-8 interest-continer">
            <div
              v-for="interest in selectedSubscription.interests"
              :key="interest.id"
            >
              {{ interest.label }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">Reached Us</div>
          <div class="col-8">
            {{ selectedSubscription.reached_us || "N/A" }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Heard From</div>
          <div class="col-8">
            {{ selectedSubscription.referred_by_friend || "N/A" }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Referred By</div>
          <div class="col-8">
            {{
              (selectedSubscription.referred_by_friend &&
                selectedSubscription.referred_by_friend.name) ||
              "N/A"
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Created At</div>
          <div class="col-8">
            {{ $moment(Date(selectedSubscription.created)).format("lll") }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Updated At</div>
          <div class="col-8">
            {{ $moment(Date(selectedSubscription.modified)).format("lll") }}
          </div>
        </div>
      </CModalBody>
      <CModalFooter style="padding-bottom: 32px">
        <div v-if="selectedSubscription.membership_status === 0">
          <CButton
            color="primary"
            style="margin-right: 18px"
            @click="approveMembership()"
            >Verify Request</CButton
          >
          <CButton class="disqualify-btn" @click="disapproveMembership()"
            >Disqualify Request</CButton
          >
        </div>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { capitalizeFirstLetter } from "@/utils/utility";
import "vue-loading-overlay/dist/vue-loading.css";
import { updateSubscriptionStatus } from "@/services/apis/subscription";
export default {
  components: {
    Loading,
  },

  data() {
    return {
      loading: true,
      fullPage: false,
      subscriptionModal: false,
      selectedSubscription: null,
      capitalizeFirstLetter: capitalizeFirstLetter,
    };
  },
  methods: {
    openModal() {
      this.subscriptionModal = true;
    },
    rowSelected(item) {
      this.selectedSubscription = item;
      this.openModal();
    },
    approveMembership() {
      this.$Swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to proceed with the membership approval!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Sure",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updataMembershipStatus(this.selectedSubscription.id, 1).then(
              (res) => {
                this.$Swal.fire(
                  "Approved!",
                  "The Membership is approved successfully.",
                  "success"
                );
                this.$store.dispatch(
                  "removeSubscriptionByID",
                  this.selectedSubscription.id
                );
              }
            );
          }
        });
    },
    disapproveMembership() {
      this.$Swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to disqualify the membership request!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Sure",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updataMembershipStatus(this.selectedSubscription.id, 4).then(
              (res) => {
                this.$Swal.fire(
                  "Disqualified!",
                  "User is  disqualified successfully.",
                  "success"
                );
                this.$store.dispatch(
                  "removeSubscriptionByID",
                  this.selectedSubscription.id
                );
              }
            );
          }
        });
    },
    async updataMembershipStatus(id, status) {
      const data = {
        membership_status: status,
      };
      return await updateSubscriptionStatus(id, data);
    },
  },
};
</script>

<style scoped>
.modal-title {
  text-align: center;
}
.interest-continer {
  display: flex;
  flex-wrap: wrap;
}
.interest-continer div {
  background-color: #000;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #c8a791;
  padding: 2px 18px;
}
.row {
  margin-bottom: 12px;
}
.modal-custom-st {
  padding: 0px 64px;
  font-size: 15px;
}
.col-4 {
  color: gray;
}
.memebrship-status {
  text-align: center;
  margin-bottom: 32px;
  color: #c8a791;
  padding: 18px 12px;
  font-size: 17px;
}
.address-container,
.contact-container {
  background-color: #fafafa;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 0.87rem;
}
.disqualify-btn {
  background-color: red;
  color: #fff;
}
</style>
